import { createSelector } from 'reselect';
import moment from 'moment';
import {
  getAveragePrice,
  getExchangeStr,
  getFeeStr,
  getMoveSLOption,
  getStart,
  getStopLoss,
  getTargets,
  getTriggerPrice,
  isTriggered,
  triggerIsDone,
} from './order';
import { getTotalUSD } from './exchange';
import { adminUrl } from '../routes/dashboard';
import { satoshi } from './general';
import { availableAmountsSelector } from '../redux/order/selector';
import { tradersSelector } from '../redux/trader/selector';
import { formatDateEN } from './localize';
import startsInit from './smartTrading/StartActions/init';
import stopsInit from './smartTrading/StopActions/init';
import targetsInit from './smartTrading/TargetActions/init';
import { getUrl } from './fetcher';

// const isFunction = funcToCheck => {
//   return funcToCheck && {}.toString.call(funcToCheck) === '[object Function]';
// };

// General

// Parser
export const parseToFloat = (value, step, trunc = false) => {
  let s = 8;
  if (!isNull(step)) {
    s = nbStep(step);
    value = parseFloat(value);
    const d = 10 ** s;
    // console.log('parseToFloat', s, step, value, d, value * d, Math.round(value * d));
    value = trunc ? Math.trunc(value * d) / d : Math.round(value * d) / d;
  }
  let v = parseFloat(value);
  if (isNaN(v)) return '';
  if (!isNull(step) && v.toString().indexOf('e') > 0) v = v.toFixed(s);
  return v;
};

// Utils
export const isNull = (v) => v === undefined || v === null; // isNaN with str = true;
export const isEmpty = (v) =>
  isNull(v) || v === '' || v === 0 || v.length === 0;
// export const isEmpty = (v) => isNull(v) || v === '' || v === 0 || isNaN(v) || v.length === 0;
export const isOrderV2 = (order) => order && !isNull(order.OrderV2Id);
export const isFuture = (v) => isBinanceFuture(v) || isBybitFuture(v);
export const isBinanceFuture = (v) =>
  !isEmpty(v) &&
  (v === 8 ||
    v === 19 ||
    v === 'Binance_Futures' ||
    v === 'Binance_Futures_Test');

export const isBybit = (v) =>
  !isEmpty(v) && (v === 16 || v === 21 || v === 'Bybit' || v === 'Bybit_Test');

export const isBybitFuture = (v) =>
  !isEmpty(v) &&
  (v === 20 || v === 22 || v === 'Bybit_Futures' || v === 'Bybit_Futures_Test');

export const nbStep = (step) => {
  if (!step || step === '') return '';
  let s = 0;
  while (step < 1) {
    ++s;
    step *= 10;
  }
  return s;
};

export const getProfitNetFromPrice = (price, isBuy, sp, fee) => {
  if (isNaN(price) || isNaN(sp)) return 0;
  if (isEmpty(sp) || isNull(fee)) return 0;
  const blf = isBuy ? sp + sp * fee : sp - sp * fee;
  const slf = isBuy ? price - price * fee : price + price * fee;
  return isBuy ? (slf - blf) / sp : (blf - slf) / sp;
};

export const getProfitFromPrice = (price, isBuy, sp) => {
  if (isNaN(price) || isNaN(sp)) return 0;
  return price === ''
    ? ''
    : isBuy
    ? (price - sp) * (100 / sp)
    : (sp - price) * (100 / price);
};

export const getPriceFromProfit = (profit, isBuy, sp) => {
  if (isNaN(profit) || isNaN(sp)) return 0;
  return profit === ''
    ? ''
    : isBuy
    ? (sp * profit) / 100 + sp
    : (100 * sp) / (profit + 100);
};

export const getPairStrFromPair = (pair) => {
  if (!pair) return '';
  if (!pair.value) {
    return pair;
  }
  return pair.value;
};

export const getPrimFromPair = (pair) => {
  const pairStr = getPairStrFromPair(pair);
  if (pairStr === '') return '';
  const pairSplit = pairStr.split('-');
  return pairSplit.length > 0 ? pairSplit[0] : '';
};

export const getSecFromPair = (pair) => {
  const pairStr = getPairStrFromPair(pair);
  if (pairStr === '') return '';
  const pairSplit = pairStr.split('-');
  return pairSplit.length > 1 ? pairSplit[1] : ''; // pairStr.substring(0, 3)
};

export const getFeeFromName = (exchangeName) => {
  switch (exchangeName) {
    case 'Bittrex':
      return 0.0025;
    case 'Binance_Spot':
    case 'Binance_Test':
    case 'Binance_US':
    case 'Binance_Margin':
      return 0.001;
    case 'Huobi':
      return 0.002;
    case 'Bitfinex':
      return 0.002; // 0.001 if maker
    case 'Bitmex':
      return 0.0; // à vérifier
    case 'Kraken':
      return 0.0026; // 0.0016 if maker
    case 'Binance_Futures':
    case 'Binance_Futures_Test':
      return 0.0004; // 0.0002 if maker
    case 'FTX':
    case 'FTX_US':
      return 0.0007; // 0.0002 if maker
    case 'Kucoin':
      return 0.001;
    case 'Bybit':
    case 'Bybit_Test':
      return 0.001;
    case 'Bybit_Futures':
    case 'Bybit_Futures_Test':
      return 0.0006; // 0.0001 if maker
    default:
      return 0;
  }
};

// SmartTrade
export const myGroupsSelector = (state) => {
  // const state = isFunction(state) ? getState() : getState;
  if (!state || !state.order) return [];
  let { myGroups } = state.order;
  if (!myGroups || myGroups.length === 0)
    myGroups = JSON.parse(localStorage.getItem('myGroups'));
  if (!myGroups || myGroups.length === 0) return [];
  return myGroups;
};

export const exchangesSelector = (state) => {
  // const state = isFunction(state) ? getState() : getState;
  if (!state || !state.user) return [];
  let { exchanges } = state.user;
  if (!exchanges || exchanges.length === 0)
    exchanges = JSON.parse(localStorage.getItem('exchanges'));

  if (!exchanges || exchanges.length === 0) return [];
  // console.log('exchangeSelector', exchanges);
  return exchanges;
};

export const smartTradeSelector = (state) => {
  // const state = isFunction(state) ? getState() : getState;
  if (
    !state ||
    !state.form ||
    (!state.form.formSmartTradeV1 && !state.form.formSmartTrade)
  )
    return {};
  const path = pathNameSelector(state);
  const isV1 = path.indexOf('V1') > 0;
  const smartTrade = !isV1
    ? state.form.formSmartTrade
    : state.form.formSmartTradeV1;
  if (!smartTrade || !smartTrade.values) return {};
  return smartTrade.values;
};

export const isUpdateSelector = createSelector(
  smartTradeSelector,
  (smartTrade) => {
    return smartTrade && Object.hasOwn(smartTrade, 'isUpdate')
      ? smartTrade.isUpdate
      : false;
  },
);
export const isBuySelector = createSelector(
  smartTradeSelector,
  (smartTrade) => {
    return smartTrade && Object.hasOwn(smartTrade, 'isBuy')
      ? smartTrade.isBuy
      : true;
  },
);
export const pathNameSelector = (state) => {
  if (
    state &&
    Object.hasOwn(state, 'router') &&
    Object.hasOwn(state.router, 'location') &&
    Object.hasOwn(state.router.location, 'pathname')
  )
    return state.router.location.pathname;
  return '';
};
export const exchangeSmartTradeSelector = createSelector(
  smartTradeSelector,
  (smartTrade) => {
    return smartTrade && Object.hasOwn(smartTrade, 'accountId')
      ? smartTrade.accountId
      : 0;
  },
);
const pathNameIsOrderOrAdmin = (path) => {
  if (isEmpty(path)) return false;
  return path.startsWith('/orders') || path.startsWith(adminUrl);
};
export const exchangeOrderSelector = (state, props) => {
  const pathName = pathNameSelector(state, props);
  if (!pathNameIsOrderOrAdmin(pathName)) return {};
  if (
    !isNull(props) &&
    Object.hasOwn(props, 'order') &&
    !isNull(props.order) &&
    Object.hasOwn(props.order, 'AAccount') &&
    Object.hasOwn(props.order.AAccount, 'ExchangeName')
  )
    return { exchange: getExchangeStr(props.order.AAccount) };
  return {};
};
export const exchangeSelector = createSelector(
  pathNameSelector,
  exchangeSmartTradeSelector,
  exchangeOrderSelector,
  (pathName, exchangeSmartTrade, exchangeOrder) => {
    if (pathName && pathName.indexOf('smartTrade') > -1) {
      return exchangeSmartTrade;
    }
    if (pathName && pathNameIsOrderOrAdmin(pathName)) {
      return exchangeOrder;
    }
    return 0;
  },
);
export const pairSmartTradeSelector = createSelector(
  smartTradeSelector,
  (smartTrade) => {
    return smartTrade && smartTrade.pair && smartTrade.pair.value
      ? smartTrade.pair.value
      : 'BTC-USDT';
  },
);
export const pairOrderSelector = (state, props) => {
  const pathName = pathNameSelector(state, props);
  if (!pathNameIsOrderOrAdmin(pathName)) return '';
  if (
    !isNull(props) &&
    Object.hasOwn(props, 'order') &&
    !isNull(props.order) &&
    Object.hasOwn(props.order, 'Pair')
  ) {
    return props.order.Pair;
  }
  return '';
};
export const pairSelector = createSelector(
  pathNameSelector,
  pairSmartTradeSelector,
  pairOrderSelector,
  (pathName, pairSmartTrade, pairOrder) => {
    if (pathName && pathName.indexOf('smartTrade') > -1) {
      return pairSmartTrade;
    }
    if (pathName && pathNameIsOrderOrAdmin(pathName)) {
      return pairOrder;
    }
    return '';
  },
);
export const currentAASelector = createSelector(
  exchangeSelector,
  availableAmountsSelector,
  (exchange, availableAmounts) => {
    if (isEmpty(exchange)) exchange = { exchange: defaultExchange };
    return exchange && exchange.exchange && availableAmounts
      ? availableAmounts.find((x) => x.ExchangeName === exchange.exchange)
      : [];
  },
);

export const getCurrentPrice = (currentAA, pairStr) => {
  if (isNull(currentAA) || isEmpty(pairStr)) return [];
  const pairPrice =
    currentAA &&
    pairStr &&
    Object.hasOwn(currentAA, 'PairsPrice') &&
    currentAA.PairsPrice.find((x) => x.Pair === pairStr);
  return pairPrice && Object.hasOwn(pairPrice, 'Last') ? pairPrice.Last : [];
};

export const currentPriceSelector = createSelector(
  pairSelector,
  currentAASelector,
  (pair, currentAA) => {
    // const exchangeName =
    //   currentAA && currentAA.ExchangeName ? currentAA.ExchangeName : '';
    return getCurrentPrice(currentAA, pair);
  },
);
export const pairInfoSelector = createSelector(
  pairSelector,
  currentAASelector,
  (pair, currentAA) => {
    const pairStr = pair;
    const pairInfo =
      currentAA &&
      pairStr &&
      currentAA.Pairs &&
      currentAA.Pairs.find((x) => x.Pair === pairStr);
    return pairInfo || null;
  },
);
export const stepPriceSelector = createSelector(
  pairInfoSelector,
  (pairInfo) => {
    return pairInfo &&
      Object.hasOwn(pairInfo, 'PrecisionPrice') &&
      pairInfo.PrecisionPrice
      ? pairInfo.PrecisionPrice
      : satoshi;
  },
);
export const stepQtySelector = createSelector(pairInfoSelector, (pairInfo) => {
  return pairInfo &&
    Object.hasOwn(pairInfo, 'PrecisionAmount') &&
    pairInfo.PrecisionAmount
    ? pairInfo.PrecisionAmount
    : satoshi;
});

export const leverageBuySelector = createSelector(
  pairInfoSelector,
  (pairInfo) => {
    return pairInfo &&
      Object.hasOwn(pairInfo, 'LeverageBuy') &&
      pairInfo.LeverageBuy
      ? pairInfo.LeverageBuy
      : null;
  },
);

export const leverageSellSelector = createSelector(
  pairInfoSelector,
  (pairInfo) => {
    return pairInfo &&
      Object.hasOwn(pairInfo, 'LeverageSell') &&
      pairInfo.LeverageSell
      ? pairInfo.LeverageSell
      : null;
  },
);

const orderSelector = (state, props) => {
  return props.order;
};

export const capitalSelector = createSelector(
  exchangeSelector,
  exchangesSelector,
  tradersSelector,
  orderSelector,
  (exchange, exchanges, traders, order) => {
    const exchangeValue = !exchange || !exchange.value ? 0 : exchange.value;
    const exchangeStr =
      !exchange || !exchange.exchange ? defaultExchange : exchange.exchange;
    const exch =
      exchangeValue === 0
        ? exchanges.find((x) => x.ExchangeName === exchangeStr)
        : exchanges.find((x) => x.Id === exchangeValue);
    const exchVal =
      exchangeValue === 0
        ? null
        : exchanges.find((x) => x.Id === exchangeValue);
    const myExchange =
      exchVal === null
        ? exchanges && exchanges.length > 0
          ? exch || exchanges[0]
          : null
        : exchVal;
    let capital =
      myExchange && myExchange.Capital === null
        ? getTotalUSD(myExchange)
        : myExchange
        ? myExchange.Capital
        : 0;
    // Si order suiveur ajuste le capital en fonction du pourcentage suivi
    if (order && order.BaseOrderId !== null) {
      const groupId = order.GroupId;
      const trader = traders.find((x) => x.GroupId === groupId);
      if (
        !isNull(trader) &&
        !isNull(trader.SubscriptionGroups) &&
        trader.SubscriptionGroups.length > 0
      ) {
        const sg = trader.SubscriptionGroups[0];
        if (sg && sg.Pourcentage > 0)
          capital = parseToFloat((capital * sg.Pourcentage) / 100, 0.01);
      }
    }
    return capital;
  },
);

export const pairsSelectSelector = createSelector(
  exchangeSelector,
  availableAmountsSelector,
  (exchange, availableAmounts) => {
    if (!exchange || !exchange.exchange) return [];
    const pair = availableAmounts.find(
      (x) => x.ExchangeName === exchange.exchange,
    );
    if (!pair || !Object.hasOwn(pair, 'PairSelect')) return [];
    return pair.PairSelect;
  },
);

export const getExchange = createSelector(
  exchangeSelector,
  exchangesSelector,
  (exchange, exchanges) => getExchangeDefault(exchange, exchanges),
);

export const getExchangesSelect = createSelector(
  exchangesSelector,
  availableAmountsSelector,
  (exchanges, availableAmounts) => {
    const selectExchange = [];
    for (const exchange of exchanges) {
      if (exchange.AccountType === 'Exchange') {
        const aa =
          exchange && exchange.ExchangeName && availableAmounts
            ? availableAmounts.find(
                (x) => x.ExchangeName === exchange.ExchangeName,
              )
            : {};
        if (
          !isNull(aa) &&
          Object.hasOwn(aa, 'PairSelect') &&
          aa.PairSelect.length > 0
        ) {
          selectExchange.push({
            label: exchange.Name,
            value: exchange.Id,
            exchange: exchange.ExchangeName,
          });
        }
      }
    }
    return selectExchange;
  },
);

export const getSimpleExchangesSelect = createSelector(
  exchangesSelector,
  (exchanges) => {
    const selectExchange = [];
    for (const exchange of exchanges) {
      if (exchange.AccountType !== 'Exchange') continue;
      selectExchange.push({
        label: exchange.Name,
        value: exchange.Id,
        exchange: exchange.ExchangeName,
      });
    }
    return selectExchange;
  },
);

export const getMyGroupsSelect = createSelector(myGroupsSelector, (groups) => {
  return groups.map((obj) => ({
    label: obj.Name,
    value: obj.GroupId,
    TotalCapital: obj.TotalCapital,
  }));
});

export const secPairSelector = createSelector(pairSelector, (pair) => {
  if (!pair) return '';
  return getSecFromPair(pair);
});

export const primPairSelector = createSelector(pairSelector, (pair) => {
  if (!pair) return '';
  return getPrimFromPair(pair);
});

export const tokenPrimSelector = createSelector(
  primPairSelector,
  getExchange,
  (pair, exchange) => {
    if (!exchange || !exchange.Holds || !pair) return null;
    if (exchange.ExchangeName === 'Bitmex') pair = 'XBT';
    // ||
    //   (pair === 'XBT' && exchange.ExchangeName === 'Kraken')
    // )
    //   pair = 'XBT';
    const token = exchange.Holds.find(
      (x) => x.Token && x.Token.Symbol === pair,
    );
    return token;
  },
);

export const qtyPrimSelector = createSelector(tokenPrimSelector, (token) => {
  return token ? token.Amount - token.Locked : 0;
  // return token ? token.Amount - token.Locked - token.LockedOnWot : 0;
});

export const tokenSecSelector = createSelector(
  secPairSelector,
  getExchange,
  (pair, exchange) => {
    if (!exchange || !exchange.Holds || !pair) return null;
    const token = exchange.Holds.find(
      (x) => x.Token && x.Token.Symbol === pair,
    );
    return token;
  },
);

export const qtySecSelector = createSelector(tokenSecSelector, (token) => {
  return token ? token.Amount - token.Locked : 0;
  // return token ? token.Amount - token.Locked - token.LockedOnWot : 0;
});

// If change think to the C#
export const getBaseUSDPairExchange = (exchange, base) => {
  if (!exchange) return `${base}-USDT`;
  let endQuote = '-USD';
  if (['Bitmex', 'Kraken'].includes(exchange)) {
    if (base === 'BTC') base = 'XBT';
    if (base.indexOf('19') >= 0) base = 'XBT';
    return base + endQuote;
  }
  // Exchange en USD
  if (!['Bitfinex', 'FTX', 'FTX_US'].includes(exchange)) {
    endQuote += 'T';
  }
  return base + endQuote;
};

export const isUSD = (pair) => {
  return (
    pair.indexOf('USD') >= 0 ||
    pair.indexOf('PAX') >= 0 ||
    (pair.indexOf('XBT') >= 0 && pair.indexOf('19') >= 0)
  );
};

export const isBTC = (pair) => {
  return (
    pair.indexOf('BTC') >= 0 ||
    pair.indexOf('XBT') >= 0 ||
    pair.indexOf('19') >= 0
  );
};

export const secPriceUSDSelector = createSelector(
  currentPriceSelector,
  orderSelector,
  currentAASelector,
  pairSelector,
  secPairSelector,
  exchangeSelector,
  (currentPrice, order, currentAA, pair, secPair, exchange) => {
    let price = currentPrice;
    const start = getStart(order.Triggers);
    if (!triggerIsDone(start)) {
      price = getTriggerPrice(start);
    }
    let priceQuote = 0;
    const pairStr = getBaseUSDPairExchange(exchange.exchange, secPair);
    if (isUSD(pair)) priceQuote = 1;
    else priceQuote = getCurrentPrice(currentAA, pairStr);
    return price * priceQuote;
  },
);

export const secPriceSelector = createSelector(
  currentAASelector,
  pairSelector,
  primPairSelector,
  secPairSelector,
  exchangeSelector,
  (currentAA, pair, primPair, secPair, exchange) => {
    // Number for option on FTX, PERP for FTX
    if (isEmpty(pair) || !isNaN(secPair) || secPair === 'PERP') return 1;
    // Si ce nest pas une paire USD
    if (exchange && exchange.exchange && secPair.indexOf('USD') < 0) {
      const pairStr = getBaseUSDPairExchange(exchange.exchange, secPair);
      // Si la paire de Bitmex commence par XBT alors prend la paire XBTUSD
      if (exchange.exchange === 'Bitmex' && primPair === 'XBT') {
        return 1;
      }
      const price = getCurrentPrice(currentAA, pairStr);
      return !isEmpty(price) ? price : 0;
    }
    return 1;
  },
);

export const getSLProfitFromPrice = (state, price) => {
  const formSmartTrade = smartTradeSelector(state);
  if (!formSmartTrade) return '';
  const { isBuy } = formSmartTrade;
  const sp = parseFloat(formSmartTrade.startAveragePrice);
  return getProfitFromPrice(price, isBuy, sp);
};

export const getSLPriceFromProfit = (state, profit) => {
  const formSmartTrade = smartTradeSelector(state);
  if (!formSmartTrade) return '';
  const { isBuy } = formSmartTrade;
  const sp = parseFloat(formSmartTrade.startAveragePrice);
  return getPriceFromProfit(profit, isBuy, sp);
};

export const getMyGroup = createSelector(
  smartTradeSelector,
  myGroupsSelector,
  (formSmartTrade, myGroups) => {
    if (!myGroups || !formSmartTrade) return '';
    const { groupId } = formSmartTrade;
    if (!groupId) return '';
    return myGroups.find((x) => x.GroupId === groupId.value);
  },
);

// DETAIL
// export const getPourcentageBrut = state => {
//   const formSmartTrade = smartTradeSelector(state);
//   if (!formSmartTrade) return '';
//   const { startAveragePrice, targets, isBuy } = formSmartTrade;
//   let value = 0.0;
//   for (const targetPrice of targets) {
//     const target = parseFloat(targetPrice.price);
//     const range = getProfitFromPrice(target, isBuy, startAveragePrice);
//     value += range * (targetPrice.pourcentage / 100);
//   }
//   return value.toFixed(2);
// };

export const getFee = createSelector(smartTradeSelector, (formSmartTrade) => {
  if (!formSmartTrade) return '';
  const { accountId } = formSmartTrade;
  if (!accountId) return '';
  const exchangeName = accountId.exchange;
  return getFeeFromName(exchangeName);
});

export const getPourcentageNet = createSelector(
  smartTradeSelector,
  getFee,
  (formSmartTrade, fee) => {
    if (!formSmartTrade) return '';
    const { startAveragePrice, targets, isBuy, leverage } = formSmartTrade;
    if (!startAveragePrice || !targets) return '';
    // const sp = parseFloat(startAveragePrice[0].price);
    const sp = parseFloat(startAveragePrice);
    // const blf = isBuy ? sp + sp * fee : sp - sp * fee;
    let value = 0.0;
    for (const targetPrice of targets) {
      // const target = parseFloat(targetPrice.price);
      // const slf = isBuy ? target - target * fee : target + target * fee;
      // // const range = isBuy ? (slf - blf) / sp : (blf - slf) / target;
      if (
        targetPrice &&
        Object.hasOwn(targetPrice, 'price') &&
        targetPrice.price &&
        Object.hasOwn(targetPrice, 'pourcentage') &&
        targetPrice.pourcentage
      ) {
        const range =
          getProfitNetFromPrice(targetPrice.price, isBuy, sp, fee) * leverage;
        value += range * targetPrice.pourcentage;
      }
    }
    return value.toFixed(2);
  },
);

export const getPourcentageSLNetV1 = createSelector(
  smartTradeSelector,
  getFee,
  (formSmartTrade, fee) => {
    if (!formSmartTrade) return '';
    const { startAveragePrice, stopPrice, isBuy, leverage } = formSmartTrade;
    const range =
      getProfitNetFromPrice(stopPrice, isBuy, startAveragePrice, fee) *
      leverage;
    return (range * 100).toFixed(2);
  },
);

export const getPourcentageSLNetV2 = createSelector(
  smartTradeSelector,
  getFee,
  (formSmartTrade, fee) => {
    if (!formSmartTrade) return '';
    const { startAveragePrice, stops, isBuy, leverage } = formSmartTrade;
    const stopAveragePrice = getAveragePrice(stops);
    const range =
      getProfitNetFromPrice(stopAveragePrice, isBuy, startAveragePrice, fee) *
      leverage;
    return (range * 100).toFixed(2);
  },
);

export const getTotalCapital = createSelector(
  getMyGroup,
  getExchange,
  (myGroup, exchange) => {
    if (!myGroup || !exchange) return 0;
    return myGroup.TotalCapital[exchange.ExchangeName]
      ? myGroup.TotalCapital[exchange.ExchangeName]
      : 0;
  },
);

export const getPairSelect = (value) => ({
  value,
  label: value,
  pair: value,
});

const defaultPairStr = 'BTC-USDT';
export const defaultExchange = 'Binance_Spot';

export const getFromValue = (form, value, def) => {
  return form && Object.hasOwn(form, value) && !!form[value]
    ? form[value]
    : def;
};

export const getPartFromPourcentage = (capital, pourcentage, leverage = 1) => {
  if (isNull(leverage)) leverage = 1;
  return isNull(capital) || isEmpty(pourcentage)
    ? ''
    : parseToFloat((capital * pourcentage * leverage) / 100, 0.01);
};

export const getPourcentageFromPart = (partUse, capital, leverage = 1) => {
  if (isNull(leverage)) leverage = 1;
  const pourcentage =
    isEmpty(partUse) || isEmpty(capital)
      ? ''
      : (partUse * 100) / capital / leverage;
  return parseToFloat(pourcentage, 0.01);
};
export const getPartFromSecQty = (secQty, secPrice) => {
  const partUse = secQty * secPrice;
  return !partUse ? '' : parseToFloat(partUse, 0.01);
};
export const getSecQtyFromPart = (partUse, secPrice, stepPrice) => {
  const qtySec = partUse / secPrice;
  return !qtySec ? '' : parseToFloat(qtySec, stepPrice);
};

export const getFirstFromSec = (
  secQty,
  startAveragePrice,
  lotStep,
  exchangeName,
  leverage,
  isBuy,
  isTaker = true,
  cl = true,
) => {
  let orderQty = parseToFloat(secQty / startAveragePrice, lotStep, true);
  if (isBybitFuture(exchangeName)) {
    // Calcul form https://www.bybit.com/en/help-center/article/Order-Cost-USDT-ContractUSDT_Perpetual_UTA
    const initialMargin = secQty / leverage;
    const fee = getFeeStr(exchangeName, isTaker);
    const feeOpenPosition = orderQty * startAveragePrice * fee;
    const bankruptcyPrice =
      (startAveragePrice * (leverage - (isBuy ? 1 : -1))) / leverage;
    const feeClosePosition = orderQty * bankruptcyPrice * fee;
    const orderCost = initialMargin + feeOpenPosition + feeClosePosition;
    const qty =
      (orderCost * leverage) /
      (startAveragePrice * (2 * fee * leverage + (1 - (isBuy ? 1 : -1) * fee)));
    // orderCost / startAveragePrice
    // const qtyFromOrderCost = parseToFloat((isBuy ? orderCost / startAveragePrice : qty), lotStep, true);
    orderQty = parseToFloat((qty * initialMargin) / orderCost, lotStep, true);
    // if (cl)
    // console.log('orderQty', orderQty, 'orderCost', orderCost, 'qty', qty, 'initialMargin', initialMargin, 'fee', fee, 'feeOpenPosition', feeOpenPosition, 'feeClosePosition', feeClosePosition, 'bankruptcyPrice', bankruptcyPrice);
  }
  // if (cl)
  // console.log('secQty', secQty, 'startAveragePrice', startAveragePrice, 'leverage', leverage, 'isBuy', isBuy);
  return !secQty ? '' : orderQty;
};

export const getSecFromFirst = (firstQty, startAveragePrice, stepPrice) => {
  return !firstQty ? '' : parseToFloat(firstQty * startAveragePrice, stepPrice);
};

const followersInit = ({ order }) => {
  if (!order || !order.Group) return { followersLock: true };

  return {
    followersLock: false,
    groupId: {
      label: order.Group.Name,
      value: order.GroupId,
    },
  };
};

export const isTriggerLineEmpty = (target) => {
  return (
    isNull(target.pourcentage) &&
    isNull(target.price) &&
    isNull(target.tradingViewGuid) &&
    !target.tradingViewCB &&
    isNull(target.trailingPrice) &&
    target.trailing === 2 &&
    !target.trailingCB &&
    !target.timeOutCB &&
    target.type === 'Limit' &&
    isNull(target.triggerPrice) &&
    !target.triggerCB &&
    !target.optionsCB &&
    target.lastUpdate === 'price'
  );
};

export const emptyTriggerModel = {
  pourcentage: null,
  price: null,
  tradingViewGuid: null,
  tradingViewCB: false,
  trailingPrice: null,
  trailing: 2,
  trailingCB: false,
  timeOut: moment().add(3, 'days').format(formatDateEN),
  timeOutCB: false,
  type: 'Limit',
  triggerPrice: null,
  triggerCB: false,
  optionsCB: false,
  lastUpdate: 'price',
  isDone: false,
};

const baseInitFromOrder = (order) => {
  if (!order || !order.AAccount) return {};

  const ret = {
    order: order,
    isUpdate: true,
    isBuy: order.IsBuy,
    accountId: {
      value: order.AAccountId,
      label: order.AAccount.Name,
      exchange: getExchangeStr(order.AAccount),
    },
    pair: getPairSelect(order.Pair),
    dontDoOrder: order.DontDoOrder,
    note: order.Note,
    isPaperTrading: order.AAccount.IsPaperTrading,
  };

  return ret;
};

const baseInit = ({ exchanges, order, exchange, form }) => {
  if (order) return baseInitFromOrder(order);

  const ret = {
    isUpdate: false,
    isBuy: getFromValue(form, 'isBuy', true),
    accountId: { value: 0, label: '', exchange: null },
    pair: getFromValue(form, 'pair', getPairSelect(defaultPairStr)),
    dontDoOrder: getFromValue(form, 'dontDoOrder', false),
    note: getFromValue(form, 'note', ''),
    isPaperTrading: false,
  };

  if (exchange)
    ret.accountId = {
      value: exchange.Id,
      label: exchange.Name,
      exchange: exchange.ExchangeName,
    };
  ret.isPaperTrading = !!(!isNull(exchange) && exchange.IsPaperTrading);

  return ret;
};

const getInitialValueFromOrderV1 = (
  order,
  capital,
  stepPrice,
  secPrice,
  lotStep,
  currentPrice,
) => {
  const ret = {};
  if (!order || !order.AAccount) return ret;
  // const fOrder = getObjOrder(order);
  const isTemplate = order.State === 9;
  const stopPrice = getStopLoss(order.Triggers);
  const targets = getTargets(order.Triggers);
  const start = getStart(order.Triggers);
  let startPrice = getTriggerPrice(start);
  if (isEmpty(startPrice)) startPrice = currentPrice;
  const isBuy = order.IsBuy;
  ret.order = order;
  ret.isUpdate = true;
  ret.lastUpdated = 'pourcentage';
  ret.isBuyDone = isNull(start) ? false : triggerIsDone(start);
  ret.dontDoOrder = order.DontDoOrder;
  ret.note = order.Note;
  ret.isPaperTrading = order.AAccount.IsPaperTrading;
  ret.TVType = order.TVType;

  // Start
  if (!isNull(start.TradingViewGuid)) {
    ret.startTradingViewGuid = start.TradingViewGuid;
    ret.tradingViewCB = true;
    ret.serverUrl = `${getUrl()}api/v1/Order/TradingView`;
    if (isTemplate) ret.isTemplate = true;
  }
  ret.isBuy = isBuy;
  ret.accountId = {
    value: order.AAccountId,
    label: order.AAccount.Name,
    exchange: getExchangeStr(order.AAccount),
  };
  ret.dontDoStart = order.DontDoStart;
  ret.pair = getPairSelect(order.Pair);
  ret.startPrice = parseToFloat(startPrice, stepPrice);
  if (start) ret.typeStart = start.OrderType === 1 ? 'Market' : 'Limit';
  ret.pourcentage = order.Pourcentage;
  ret.leverage = 1;
  if (order.Leverage != null) {
    ret.leverageCB = true;
    ret.leverage = order.Leverage;
  }
  if (ret.isBuyDone) {
    ret.firstQty = order.Quantity;
    ret.secQty = getSecFromFirst(ret.firstQty, startPrice, stepPrice);
    ret.partUse = getPartFromSecQty(ret.secQty, secPrice);
  } else {
    ret.partUse = getPartFromPourcentage(
      capital,
      ret.pourcentage,
      ret.leverage,
    );
    ret.secQty = getSecQtyFromPart(ret.partUse, secPrice, stepPrice);
    ret.firstQty = getFirstFromSec(
      ret.secQty,
      startPrice,
      lotStep,
      getExchangeStr(order.AAccount),
      ret.leverage,
      ret.isBuy,
      ret.typeStart === 'Market',
      false,
    );
  }
  if (start.Trailing) {
    ret.trailingStart = start.Trailing;
    ret.trailingStartCB = true;
  }
  if (start.TriggerPrice && start.TriggerPrice > 0 && !isTriggered(start)) {
    ret.startTriggerPriceCB = true;
    ret.startTriggerPrice = start.TriggerPrice;
  }
  if (start.TimeOut) {
    ret.timeOutStartCB = true;
    ret.timeOutStart = start.TimeOut;
  }

  // Targets
  const baseStartPrice = ret.startPrice === 0 ? currentPrice : ret.startPrice;
  ret.targets = [];
  let i = 0;
  if (targets && targets.length > 0) {
    for (const t of targets) {
      const price = isTemplate
        ? null
        : !isNull(t.Price)
        ? t.Price
        : getPriceFromProfit(t.Profit, isBuy, baseStartPrice);
      const profit = !isNull(t.Profit)
        ? t.Profit
        : getProfitFromPrice(price, isBuy, baseStartPrice);
      const trailingPrice =
        isNull(t.Trailing) || isNull(price)
          ? null
          : getPriceFromProfit(-1 * t.Trailing, isBuy, price);
      ret.targets[i] = {
        lastUpdated: !isNull(t.Profit) ? 'profit' : 'price',
        orderType: t.OrderType === 0 ? 'Limit' : 'Market',
        trailing: t.Trailing !== null ? t.Trailing : null,
        trailingPrice: trailingPrice,
        trailingCB: t.Trailing !== null,
        pourcentage: t.Pourcentage,
        price: parseToFloat(price, stepPrice),
        triggerId: t.TriggerId,
        profit: parseToFloat(profit, 0.01),
        target: t,
      };
      ++i;
    }
    ret.targetsLock = false;
  } else {
    ret.targetsLock = true;
    //Default value if actived
    ret.targets[0] = {
      lastUpdated: 'profit',
      pourcentage: 100,
      price: parseToFloat(
        ret.isBuyDone ? currentPrice : ret.startPrice * 1.02,
        stepPrice,
      ),
      orderType: 'Limit',
      trailing: 2,
      trailingCB: false,
      profit: 2,
    };
  }

  // Group
  if (order.Group) {
    ret.groupId = {
      label: order.Group.Name,
      value: order.GroupId,
    };
    ret.followersLock = false;
  } else {
    ret.followersLock = true;
  }

  // StopLoss
  if (stopPrice) {
    const tmpStopPrice = isEmpty(stopPrice.Price)
      ? getPriceFromProfit(stopPrice.Profit, isBuy, startPrice)
      : stopPrice.Price;
    ret.stopPrice = parseToFloat(tmpStopPrice, stepPrice);
    const nProfit = parseFloat(
      getProfitFromPrice(ret.stopPrice, isBuy, startPrice),
    );
    ret.stopProfit = !isNull(stopPrice.Profit)
      ? stopPrice.Profit
      : !isEmpty(nProfit)
      ? parseToFloat(nProfit, 0.01)
      : nProfit;

    if (stopPrice.TimeOut) {
      ret.timeOutStopLossCB = true;
      ret.timeOutStopLoss = stopPrice.TimeOut;
    }
    if (stopPrice.Trailing) {
      ret.trailingStopLossCB = true;
      ret.trailingStopLoss = stopPrice.Trailing * -1;
      ret.stopPrice = parseToFloat(
        getPriceFromProfit(
          (isBuy ? 1 : 1) * ret.trailingStopLoss,
          isBuy,
          ret.isBuyDone ? currentPrice : ret.startPrice,
        ),
        stepPrice,
      );
    }
    ret.orderTypeStop = stopPrice.OrderType === 0 ? 'Limit' : 'Market';
    ret.stopsLock = false;
    ret.moveSLCB = false;
    if (order.MoveSL !== null) {
      const moveSLOption = getMoveSLOption(order.MoveSL);
      if (!isNull(moveSLOption)) {
        ret.moveSL = moveSLOption;
        ret.moveSLCB = true;
      }
    }
  } else {
    ret.stopsLock = true;
    //Default value if actived
    ret.orderTypeStop = 'Limit';
    // Stop
    ret.stopPrice = parseToFloat(
      ret.isBuyDone ? currentPrice : ret.startPrice * 0.98,
      stepPrice,
    );
    ret.stopProfit = -2;
    ret.lastUpdatedStop = 'profit';
    ret.trailingStopLoss = -2;
  }

  // Timeout
  const timeOutDefault = moment().add(3, 'days').format(formatDateEN);
  if (isNull(ret.timeOutStart)) {
    ret.timeOutStart = timeOutDefault;
  }
  if (isNull(ret.timeOutStopLoss)) {
    ret.timeOutStopLoss = timeOutDefault;
  }
  return ret;
};

export const initialValuesSelectorV1 = createSelector(
  exchangesSelector,
  orderSelector,
  exchangeSelector,
  currentPriceSelector,
  pairSelector,
  smartTradeSelector,
  secPriceSelector,
  stepPriceSelector,
  stepQtySelector,
  capitalSelector,
  // isUpdateSelector,
  // defaultPairSelector,
  (
    exchanges,
    order,
    ex,
    currentPrice,
    pair,
    form,
    secPrice,
    stepPrice,
    lotStep,
    capital,
  ) => {
    // console.log('defaultPair', defaultPair);
    // console.log(
    //   'initialValuesSelector',
    //   exchanges,
    //   order,
    //   ex,
    //   currentPrice,
    //   pair,
    //   form,
    //   secPrice,
    //   stepPrice,
    //   lotStep,
    //   capital
    // );
    // if (ex === {} || ex === 0 || pair === []) return {};

    // if (isUpdate) return {};

    const exchange = getExchangeDefault(ex, exchanges);
    const exchangeStr = exchange?.ExchangeName ?? defaultExchange;

    const isPaperTrading = !!(!isNull(exchange) && exchange.IsPaperTrading);
    if (order)
      return getInitialValueFromOrderV1(
        order,
        capital,
        stepPrice,
        secPrice,
        lotStep,
        currentPrice,
      );
    // console.log('Why ?');
    const typeStart = getFromValue(form, 'typeStart', 'Limit');
    const isLimit = typeStart === 'Limit';
    const pourcentage = getFromValue(form, 'pourcentage', 5);
    const leverage = getFromValue(form, 'leverage', 1);
    const startPrice = isLimit
      ? getFromValue(form, 'startPrice', currentPrice)
      : currentPrice;
    const isBuy = getFromValue(form, 'isBuy', true);
    const partUse = getFromValue(
      form,
      'partUse',
      getPartFromPourcentage(capital, pourcentage, leverage),
    );
    const secQty = getFromValue(
      form,
      'secQty',
      getSecQtyFromPart(partUse, secPrice, stepPrice),
    );
    const firstQty = getFromValue(
      form,
      'firstQty',
      getFirstFromSec(
        secQty,
        startPrice,
        lotStep,
        exchangeStr,
        leverage,
        isBuy,
        typeStart === 'Market',
        false,
      ),
    );
    // console.log(
    //   'initialValuesSelector',
    //   exchange,
    //   pourcentage,
    //   isNull(capital) ? '' : (capital * pourcentage) / 100
    // );
    // const newPrice = getPriceFromProfit(trailingStopLoss, isBuy, startPrice);
    // const newProfit = getProfitFromPrice(stopPrice, isBuy, startPrice);
    const timeOutDefault = moment().add(3, 'days').format(formatDateEN);

    return {
      isBuyDone: false,
      isUpdate: false,
      // only front
      // marketPrice: getFromValue(form, 'marketPrice', false),
      typeStart: typeStart,
      trailingStartCB: getFromValue(form, 'trailingStartCB', false),
      trailingStopLossCB: getFromValue(form, 'trailingStopLossCB', false),
      // stopLossTimeoutCB: getFromValue(form, 'stopLossTimeoutCB', false),
      leverageCB: getFromValue(form, 'leverageCB', false),

      targetsLock: getFromValue(form, 'targetsLock', true),
      stopsLock: getFromValue(form, 'stopsLock', true),
      followersLock: getFromValue(form, 'followersLock', true),
      // panicSellLock: getFromValue(form, 'panicSellLock', true),
      // panicSellTimeout: getFromValue(form, 'panicSellTimeout', 60),

      // need
      TVType: getFromValue(form, 'TVType', 0),
      trailingStart: getFromValue(form, 'trailingStart', 1),
      isBuy: isBuy,
      startTriggerPriceCB: getFromValue(form, 'startTriggerPriceCB', false),
      startTriggerPrice: getFromValue(form, 'startTriggerPrice', currentPrice),
      startPrice: startPrice,
      pourcentage,
      partUse,
      firstQty,
      secQty,
      lastUpdated: getFromValue(form, 'lastUpdated', 'pourcentage'),
      accountId: !exchange
        ? { value: 0, label: '' }
        : {
            value: exchange.Id,
            label: exchange.Name,
            exchange: exchange.ExchangeName,
          },
      pair: getFromValue(form, 'pair', getPairSelect(defaultPairStr)),
      leverage,
      targets: getFromValue(form, 'targets', [
        {
          lastUpdated: 'profit',
          pourcentage: 100,
          price: parseToFloat(currentPrice * 1.02, stepPrice),
          orderType: 'Limit',
          trailing: 2,
          trailingCB: false,
          profit: 2,
        },
      ]),
      // Stop
      stopPrice: getFromValue(
        form,
        'stopPrice',
        parseToFloat(currentPrice * 0.98, stepPrice),
      ),
      timeOutStartCB: getFromValue(form, 'timeOutStartCB', false),
      timeOutStopLossCB: getFromValue(form, 'timeOutStopLossCB', false),
      timeOutStart: getFromValue(form, 'timeOutStart', timeOutDefault),
      timeOutStopLoss: getFromValue(form, 'timeOutStopLoss', timeOutDefault),
      stopProfit: getFromValue(form, 'stopProfit', -2),
      lastUpdatedStop: getFromValue(form, 'lastUpdatedStop', 'profit'),
      trailingStopLoss: getFromValue(form, 'trailingStopLoss', -2),
      orderTypeStop: getFromValue(form, 'orderTypeStop', 'Limit'),
      // stopLossTimeout: getFromValue(form, 'stopLossTimeout', 1),
      isPaperTrading,
      dontDoOrder: getFromValue(form, 'dontDoOrder', false),
      dontDoStart: getFromValue(form, 'dontDoStart', false),
      note: getFromValue(form, 'note', ''),
    };
  },
);

const getExchangeDefaultStr = (ex) => !ex || !ex.exchange ? defaultExchange : ex.exchange;
const getExchangeDefault = (ex, exchanges)=> {
  const exchangeValue = !ex || !ex.value ? 0 : ex.value;
  const exchangeStr = getExchangeDefaultStr(ex);
  const exch =
    exchangeValue === 0
      ? exchanges.find((x) => x.ExchangeName === exchangeStr)
      : exchanges.find((x) => x.Id === exchangeValue);
  return exchanges && exchanges.length > 0 ? exch || exchanges[0] : null;
}

export const initialValuesSelector = createSelector(
  exchangesSelector,
  orderSelector,
  exchangeSelector,
  currentPriceSelector,
  pairSelector,
  smartTradeSelector,
  secPriceSelector,
  stepPriceSelector,
  stepQtySelector,
  capitalSelector,
  (
    exchanges,
    order,
    ex,
    currentPrice,
    pair,
    form,
    secPrice,
    stepPrice,
    lotStep,
    capital,
  ) => {
    const correctCurrentPrice = isEmpty(currentPrice) ? 0 : currentPrice;
    const exchange = getExchangeDefault(ex, exchanges);

    const startInit = startsInit({
      order,
      exchange,
      correctCurrentPrice,
      form,
      secPrice,
      stepPrice,
      lotStep,
      capital,
    });

    const ret = {
      ...baseInit({ exchanges, order, exchange, form }),
      ...startInit,
      ...targetsInit({
        order,
        correctCurrentPrice,
        form,
        stepPrice,
        starts: startInit.starts,
      }),
      ...stopsInit({
        order,
        correctCurrentPrice,
        form,
        stepPrice,
        starts: startInit.starts,
      }),
      ...followersInit({ order }),
    };
    return ret;
  },
);
