import React from 'react';
import {
  Field,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
  initialize,
  change,
} from 'redux-form';

import Button from '../../../components/CustomButtons/Button.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import ReactSelect from '../../../components/Form/ReactSelectUI';
import ReactTextField from '../../../components/Form/ReactTextFieldUI';
import validate from './validate';
import { getTranslate, Translate, withLocalize } from 'react-localize-redux';

import { required } from '../../../components/Form/validator';

import { connect } from 'react-redux';
import Price from '../../../components/Fields/Price';
import RadioGroup from '../../../components/Form/ReactRadioGroupUI';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import {
  getApiLength,
  isHedgeMode,
  optionsExchanges,
} from '../../../helpers/exchange';
import CheckBox from '../../../components/Form/Checkbox';
import LabelCheckbox from '../../../components/Form/LabelCheckbox';
import {
  getExchangesSelect,
  isBinanceFuture,
  isBybit,
  isBybitFuture,
  isNull,
} from '../../../helpers/Common';
import Warning from '../../../components/Typography/Warning';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import Info from '../../../components/Typography/Info';
import { getExchangeExEnum, getExchangeExStr } from '../../../helpers/order';
import Grid from '@material-ui/core/Grid';
import SwitchUI from '@material-ui/core/Switch/Switch';

const formName = 'formExchange';

class FormExchange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exchange: null,
      disabledClick: false,
      displayWarning: false,
    };
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.state.exchange === null && this.props.exchange !== null) {
      // console.log('INITILIZED', this.props.initialValues,this.props.order);
      this.props.initialize(this.props.initialValues);
      // this.props.reset();
      this.setState({ exchange: this.props.exchange });
    }
  };

  render() {
    const {
      handleSubmit,
      errors,
      capitalRadio,
      isUpdate,
      isPaperTrading,
      exchangeName,
      translate,
      exchangesSelect,
      theme,
      hedgeMode,
    } = this.props;
    const { disabledClick, displayWarning } = this.state;

    const hasErrors = Object.keys(errors).length !== 0;
    const isFix = capitalRadio === 'Fixe';
    const sameExchange =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangesSelect.filter((x) => x.exchange === exchangeName.exchange)
        .length > 0;
    const isBinance =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange === 'Binance_Spot';
    const isBinanceFutures =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      isBinanceFuture(exchangeName.exchange);
    const isFTX =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange.indexOf('FTX') !== -1;
    const isKucoin =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange === 'Kucoin';
    const isOkx =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      exchangeName.exchange === 'Okx';
    const isBybits =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      isBybit(exchangeName.exchange);
    const isBybitFutures =
      exchangeName &&
      Object.hasOwn(exchangeName, 'exchange') &&
      isBybitFuture(exchangeName.exchange);
    const needPassphrase = isKucoin || isOkx;
    const IPs = ['54.38.230.54', '145.239.120.101', '147.135.182.132'];

    const updateDisplayWarning = (bool = false) => {
      if (isUpdate && displayWarning !== bool) {
        this.setState({ displayWarning: bool });
      }
    };

    return (
      <div>
        <form>
          <Grid container>
            <GridItem xs={12} sm={12} md={6} style={{ marginTop: '15px' }}>
              <Field
                name="exchangeName"
                label={<Translate id="exchanges.exchangeName" />}
                options={optionsExchanges}
                fullWidth={true}
                component={ReactSelect}
                validate={[required]}
                required
                disabled={isUpdate}
                onChange={(v) => {
                  if (!isNull(v)) {
                    const newName =
                      v.exchange.replaceAll('_', ' ') +
                      ' ' +
                      (isPaperTrading ? translate('exchanges.paper') : '');
                    this.props.change('name', newName);
                  }
                }}
                //multi
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} style={{ marginTop: '15px' }}>
              <Field
                name="name"
                label={<Translate id="central.name" />}
                fullWidth={true}
                component={ReactTextField}
                required
              />
            </GridItem>
            {(isBinance || isBinanceFutures) && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ marginBottom: '10px' }}
              >
                <Info severity="success" color="success">
                  <div className="icons-text">
                    <InfoIcon />
                  </div>
                  <Translate
                    id="central.selectBinanceFutures"
                    data={{
                      classLink:
                        'link-text ' + (theme === 'dark' ? 'dark' : 'light'),
                    }}
                    options={{ renderInnerHtml: true }}
                  />
                </Info>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '10px' }}>
              <Warning severity="warning" color="warning">
                <div className="icons-text">
                  <WarningIcon />
                </div>
                <Translate
                  id="central.showIP"
                  data={{ ips: IPs.join(isBinance ? ' ' : ',') }}
                  options={{ renderInnerHtml: true }}
                />
              </Warning>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <Field
                name="isPaperTrading"
                id="isPaperTrading"
                component={CheckBox}
                type="checkbox"
                onChange={() => {
                  if (!isNull(exchangeName)) {
                    const newName =
                      exchangeName.exchange.replaceAll('_', ' ') +
                      ' ' +
                      (!isPaperTrading ? translate('exchanges.paper') : '');
                    this.props.change('name', newName);
                  }
                }}
              />
              <LabelCheckbox htmlFor="isPaperTrading">
                <Translate id="exchanges.paperWallet" />
              </LabelCheckbox>
            </GridItem>
            <GridItem xs={12} sm={12} md={9} hidden={isPaperTrading}>
              <Field
                name="capitalRadio"
                id="capitalRadio"
                component={RadioGroup}
              >
                <FormControlLabel
                  value="Auto"
                  control={<Radio color="primary" />}
                  label={<Translate id="exchanges.takeAllCapital" />}
                />
                <FormControlLabel
                  value="Fixe"
                  control={<Radio color="primary" />}
                  label={<Translate id="exchanges.defineCapital" />}
                />
              </Field>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              hidden={!isFix && !isPaperTrading}
              style={{ marginBottom: '-20px' }}
            >
              <Price
                name="capital"
                label={<Translate id="exchanges.capital" />}
                endAdornment="$"
                step={10}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              hidden={(!isBinanceFutures && !isBybitFutures) || isPaperTrading}
            >
              <Translate id="exchanges.oneWayMode" />
              &nbsp;
              <SwitchUI
                checked={hedgeMode === true}
                onChange={() => {
                  this.props.change('hedgeMode', !hedgeMode);
                }}
                color="primary"
              />
              <Translate id="exchanges.hedgeMode" />
            </GridItem>
            {displayWarning && (
              <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '10px' }}>
                <Warning severity="warning" color="warning">
                  <div className="icons-text">
                    <WarningIcon />
                  </div>
                  <Translate id="central.apiKeysUpdateWarning" />
                </Warning>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12} hidden={isPaperTrading}>
              <Field
                name="apiKey"
                label={<Translate id="exchanges.APIkey" />}
                fullWidth={true}
                component={ReactTextField}
                inputProps={{ autoComplete: 'off' }}
                required
                onFocus={() => updateDisplayWarning(true)}
                onBlur={() => updateDisplayWarning(false)}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              hidden={isPaperTrading}
              style={{ marginTop: '-20px' }}
            >
              <Field
                name="apiSecret"
                label={<Translate id="exchanges.APIsecret" />}
                fullWidth={true}
                component={ReactTextField}
                inputProps={{ autoComplete: 'off' }}
                required
                onFocus={() => updateDisplayWarning(true)}
                onBlur={() => updateDisplayWarning(false)}
              />
            </GridItem>
            {needPassphrase && (
              <GridItem xs={12} sm={12} md={12} hidden={isPaperTrading}>
                <Field
                  name="passPhrase"
                  label={<Translate id="exchanges.PassPhrase" />}
                  fullWidth={true}
                  component={ReactTextField}
                  inputProps={{ autoComplete: 'off' }}
                  required
                />
              </GridItem>
            )}
            {isFTX && (
              <GridItem xs={12} sm={12} md={12} hidden={isPaperTrading}>
                <Field
                  name="subAccount"
                  label={<Translate id="exchanges.SubAccount" />}
                  fullWidth={true}
                  component={ReactTextField}
                  inputProps={{ autoComplete: 'off' }}
                />
              </GridItem>
            )}
          </Grid>
        </form>
        <GridContainer justifyContent="center">
          {/*disabled={submitting}*/}
          <Button
            type="submit"
            onClick={() => {
              this.setState({ disabledClick: true });
              setTimeout(() => {
                this.setState({ disabledClick: false });
              }, 3000);
              handleSubmit();
            }}
            color="primary"
            round
            disabled={hasErrors || disabledClick}
          >
            {isUpdate && <Translate id="exchanges.updateExchange" />}
            {!isUpdate && <Translate id="exchanges.addExchange" />}
          </Button>
        </GridContainer>
      </div>
    );
  }
}

const formExchange = reduxForm({
  form: formName, // a unique identifier for this form
  touchOnChange: true,
  validate,
})(FormExchange);

const exchangeSelector = (state, props) => {
  return props.exchange;
};

const initialValuesSelector = createSelector(exchangeSelector, (exchange) => {
  if (!isNull(exchange)) {
    const exchangeStr = exchange.ExchangeName;
    const exchangeValue = getExchangeExEnum(exchangeStr);
    const apiLength = getApiLength(exchangeStr);
    const hedgeMode = isHedgeMode(exchange?.Options);
    return {
      isUpdate: true,
      accountType: 'Exchange',
      id: exchange.Id,
      name: exchange.Name,
      hedgeMode: hedgeMode,
      exchangeName: {
        value: exchangeValue,
        label: exchange.ExchangeName,
        exchange: exchange.ExchangeName,
      },
      capitalRadio: exchange.Capital === null ? 'Auto' : 'Fixe',
      capital: exchange.Capital,
      isPaperTrading: exchange.IsPaperTrading,
      apiKey: exchange.APIKey,
      apiSecret: '*'.repeat(apiLength.apiSecret ?? 32),
    };
  }
  const defaultExchange = 'Binance_Spot';
  return {
    isUpdate: false,
    accountType: 'Exchange',
    name: 'Binance Spot',
    exchangeName: {
      value: getExchangeExEnum(defaultExchange),
      label: 'Binance_Spot',
      exchange: defaultExchange,
    },
    capitalRadio: 'Auto',
  };
});

const mapStateToProps = (state, props) => {
  const { localize, config } = state;
  const formSelector = formValueSelector(formName);
  return {
    translate: getTranslate(localize),
    exchangesSelect: getExchangesSelect(state, props),
    errors: getFormSyncErrors(formName)(state),
    isPaperTrading: formSelector(state, 'isPaperTrading'),
    capitalRadio: formSelector(state, 'capitalRadio'),
    exchangeName: formSelector(state, 'exchangeName'),
    hedgeMode: formSelector(state, 'hedgeMode'),
    isUpdate: formSelector(state, 'isUpdate'),
    initialValues: initialValuesSelector(state, props),
    theme: config.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialize: bindActionCreators(initialize, dispatch),
    change: bindActionCreators(change, dispatch),
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(formExchange),
);
